<template>
  <v-list-item class="mb-0 justify-space-between pl-3 mt-1" rounded="false">
    <v-avatar tile size="40" class="pa-1"> </v-avatar>

    <v-list-item-content class="pl-5">
      <v-list-item-title class="text-h3">
        <v-img
       
          :src="require('@/assets/MyFamily-nikita-logo.svg')"
        />

        <!-- <span class="primary--text">FREE</span> -->
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",

  computed: { version: get("app/version") },
};
</script>


